import { useRouter as useNextRouter } from "next/router";
import { useState } from "react";
import { useRouter } from "solito/router";

import type { IconProps } from "@tamagui/helpers-icon";
import {
  Home as HomeIcon,
  Inbox as InboxIcon,
  Settings as SettingsIcon,
  ShieldPlus as ShieldPlusIcon,
  WalletCards as WalletCardsIcon,
} from "@tamagui/lucide-icons";

import {
  AnimatePresence,
  Paragraph,
  Stack,
  type StackProps,
  type TabLayout,
  Tabs,
  type TabsProps,
  type TabsTabProps,
} from "@medbillai/ui";

const casesUrls = ["/cases", "/case"];
const billsUrls = ["/bills", "/bill", "/bill-dispute"];
const insuranceUrls = ["/insurance"];
const settings = ["/settings", "/hipaa-release", "/people"];
const tabPathMatch = (pathName: string) => {
  const segments = pathName.split("/");
  const firstSection = "/" + (segments[1] || "");
  if (casesUrls.includes(firstSection)) {
    return "/cases";
  }
  if (billsUrls.includes(firstSection)) {
    return "/bills";
  }
  if (insuranceUrls.includes(firstSection)) {
    return "/insurance";
  }
  if (settings.includes(firstSection)) {
    return "/settings";
  }
  return "/";
};

/**
 * this component is web-only
 */
export const NavTabs = (props: TabsProps) => {
  const nextRouter = useNextRouter();
  const router = useRouter();
  const currentTab = nextRouter.pathname;
  const setCurrentTab = (newRoute: string) => router.push(newRoute);
  /**
   * Layout of the Tab user might intend to select (hovering / focusing)
   */
  const [intentAt, setIntentIndicator] = useState<TabLayout | null>(null);

  const handleOnInteraction: TabsTabProps["onInteraction"] = (type, layout) => {
    setIntentIndicator(layout);
  };

  return (
    <Tabs
      $gtSm={{ mb: "$-0.75" }} // for the active TabsRovingIndicator to look good
      value={currentTab}
      onValueChange={setCurrentTab}
      activationMode="manual"
      {...props}
    >
      <AnimatePresence>
        {intentAt && (
          <TabsRovingIndicator
            key="intent-indicator"
            borderRadius="$4"
            width={intentAt.width}
            height={intentAt.height - 12}
            x={intentAt.x}
            y={intentAt.y + 4}
            $sm={{ display: "none" }}
          />
        )}
      </AnimatePresence>
      <Tabs.List
        unstyled
        aria-label="Navigate through the pages"
        disablePassBorderRadius
        loop={false}
        w="100%"
        f={1}
        flexDirection={props.orientation === "horizontal" ? "row" : "column"} // temp fix: would be fixed after https://github.com/tamagui/tamagui/pull/1313
      >
        <Tab
          value="/"
          currentTab={currentTab}
          icon={HomeIcon}
          onInteraction={handleOnInteraction}
          orientation={props.orientation}
        >
          Home
        </Tab>
        <Tab
          value="/insurance"
          currentTab={currentTab}
          icon={ShieldPlusIcon}
          onInteraction={handleOnInteraction}
          orientation={props.orientation}
        >
          Insurance
        </Tab>
        <Tab
          value="/cases"
          currentTab={currentTab}
          icon={InboxIcon}
          onInteraction={handleOnInteraction}
          orientation={props.orientation}
        >
          Cases
        </Tab>
        <Tab
          value="/bills"
          currentTab={currentTab}
          icon={WalletCardsIcon}
          onInteraction={handleOnInteraction}
          orientation={props.orientation}
        >
          Bills
        </Tab>
        <Tab
          value="/settings"
          currentTab={currentTab}
          icon={SettingsIcon}
          onInteraction={handleOnInteraction}
          orientation={props.orientation}
        >
          Settings
        </Tab>
      </Tabs.List>
    </Tabs>
  );
};

const Tab = ({
  currentTab,
  icon: Icon,
  ...props
}: {
  currentTab: string;
  orientation: "horizontal" | "vertical" | undefined;
  icon: React.NamedExoticComponent<IconProps>;
} & TabsTabProps) => {
  const activeTab = props.value === tabPathMatch(currentTab);
  return (
    <Tabs.Tab
      cursor="pointer"
      unstyled
      jc="flex-end"
      p="$3"
      m="$1.5"
      mb={0}
      zIndex={10}
      gap="$2"
      alignItems="center"
      borderColor={activeTab ? "$color" : "transparent"}
      borderWidth={0}
      borderRightWidth={props.orientation === "vertical" ? 2 : 0}
      borderBottomWidth={props.orientation === "horizontal" ? 2 : 0}
      animation="200ms easeOut"
      {...props}
    >
      <Icon
        cursor="pointer"
        size={18}
        animation="200ms easeOut"
        color={activeTab ? "$color" : "$textSecondary"}
        strokeWidth={2}
      />
      <Paragraph
        cursor="pointer"
        userSelect="none"
        bold={true}
        color={activeTab ? "$color" : "$textSecondary"}
      >
        {props.children}
      </Paragraph>
    </Tabs.Tab>
  );
};

const TabsRovingIndicator = ({
  active,
  ...props
}: { active?: boolean } & StackProps) => (
  <Stack
    cursor="pointer"
    position="absolute"
    backgroundColor="$color5"
    animation="100ms"
    opacity={1}
    enterStyle={{
      opacity: 0,
    }}
    exitStyle={{
      opacity: 0,
    }}
    {...(active && {
      backgroundColor: "$color9",
    })}
    {...props}
  />
);
