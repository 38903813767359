import { useRouter } from "next/router";
import type React from "react";

import { useAuth } from "@medbillai/app/lib/auth";

import { FullscreenSpinner } from "@medbillai/ui";

import {
  billsUrl,
  homeUrl,
  insuranceUrl,
  issuesUrl,
  loginUrl,
} from "@medbillai/utils/urls";

// All base urls that are never referenced by notification links
const validAccessUrls = [homeUrl(), insuranceUrl(), billsUrl(), issuesUrl()];

export const AuthGate = (props: { children: React.ReactNode }) => {
  const { isReady, isAuthenticated } = useAuth();
  const { replace, pathname } = useRouter();

  if (!isReady) {
    return <FullscreenSpinner />;
  }

  if (!isAuthenticated) {
    if (validAccessUrls.includes(pathname)) {
      void replace(loginUrl());
    } else {
      void replace(loginUrl({ invalidAccess: true }));
    }
    return <FullscreenSpinner />;
  }

  return <>{props.children}</>;
};
